import './Layout.css';

import React, { useEffect } from 'react';
import { Layout, message, Modal } from 'antd';
import axios from 'axios';

import LgpdBanner from 'components/Common/Lgpd/Banner';

import { useClienteContext } from 'contexts/ClienteContext';
import { clearEndereco, useEnderecoContext } from 'contexts/EnderecoContext';
import {
  setTaxaEntrega,
  setAbonoTaxaEntrega,
  useCarrinhoContext
} from 'contexts/CarrinhoContext';
// import { obterValorMonetario } from 'services/precos';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { unaccentCaseInsensitiveEqual } from 'utils/normalize';
import { pedidosPendentes as arrayPedidosPendentes } from 'utils/Pedido';

import DeliveryFooter from './Footer/DeliveryFooter';
import DeliveryHeader from './Header';
import NotificacaoPagamentoPendente from './NotificacaoPagamentoPendente';
import get_sigla_uf from './utils';

const isLocalizacaoDentroAreaEntrega = (areaEntrega, localizacao) => {
  const { center, radius } = areaEntrega.geo_data;

  const distancia = window.google.maps.geometry.spherical.computeDistanceBetween(
    localizacao,
    center
  );

  return distancia <= radius;
};

const estabelecimentoEntregaAreaCliente = (
  areasEntrega,
  { bairro_cliente, cidade_cliente, localizacao_cliente, uf_cliente }
) => {
  const areas = areasEntrega.filter((areaEntrega) =>
    isLocalizacaoDentroAreaEntrega(areaEntrega, localizacao_cliente)
  );

  if (
    areas.length === 0 ||
    areas.some((areaEntrega) => areaEntrega.taxaentrega === null)
  ) {
    return false;
  }

  const { taxaentrega, valorminimo } = areas.reduce(
    (areaEntregaFinal, areaEntrega) => {
      return Number(areaEntregaFinal.taxaentrega) < Number(areaEntrega.taxaentrega)
        ? areaEntregaFinal
        : areaEntrega;
    },
    { taxaentrega: Number.MAX_SAFE_INTEGER }
  );

  return {
    abonotaxaentrega_set: [{ valorminimo: valorminimo || null }],
    bairro: bairro_cliente,
    valor: taxaentrega
  };
};

const DeliveryLayout = ({ children }) => {
  const { Content } = Layout;
  const [width] = useDeviceWidth();
  const { carrinho, carrinhoDispatcher } = useCarrinhoContext();
  const { estabelecimento } = carrinho;
  const { endereco, enderecoDispatcher } = useEnderecoContext();
  const { cliente } = useClienteContext();

  const pedidosPendentes = arrayPedidosPendentes(cliente.pedidos);

  useEffect(() => {
    if (endereco && estabelecimento && window.google?.maps) {
      const {
        bairro: bairro_cliente,
        cidade: cidade_cliente,
        numero: numero_cliente,
        rua: rua_cliente,
        uf: uf_cliente
      } = endereco;
      const {
        apelidooufantasia,
        areasEntrega,
        nomeourazaosocial,
        taxasentrega
      } = estabelecimento;

      const definirEMostrarDadosEntrega = (dadosTaxa) => {
        if (dadosTaxa && estabelecimento && endereco !== null) {
          const { bairro, valor, abonotaxaentrega_set } = dadosTaxa;
          const descricaoEntrega =
            valor === 0
              ? 'não cobra taxa de entrega'
              : `cobra uma taxa de entrega de R$ ${valor}`;

          const [abono] =
            abonotaxaentrega_set.length > 0
              ? abonotaxaentrega_set
              : [{ valorminimo: null }];

          Modal.info({
            // cancelText: 'Não, receberei no estabelecimento',
            content: `
              O estabelecimento ${apelidooufantasia || nomeourazaosocial}
              ${descricaoEntrega} para o bairro ${bairro}.

            `,
            keyboard: false,
            okText: 'Ok, entendi',
            title: 'Taxa de entrega',
            onOk: () => {
              carrinhoDispatcher(setTaxaEntrega(Number(valor)));
              abono.valorminimo
                ? carrinhoDispatcher(
                    setAbonoTaxaEntrega(Number(abono.valorminimo))
                  )
                : carrinhoDispatcher(setAbonoTaxaEntrega(null));
            }
            // onCancel: () => carrinhoDispatcher(setTaxaEntrega(null))
          });
        } else {
          Modal.info({
            content: `
              O estabelecimento ${apelidooufantasia || nomeourazaosocial}
              não realiza entregas no seu bairro.
              O seu pedido deverá ser retirado no estabelecimento.
            `,
            keyboard: false,
            okText: 'Ok, entendi',
            title: 'Taxa de entrega',
            onOk: () => {
              carrinhoDispatcher(setTaxaEntrega(null));
              carrinhoDispatcher(setAbonoTaxaEntrega(null));
            }
          });
        }
      };

      if (areasEntrega.length > 0) {
        const geocoder = new window.google.maps.Geocoder();

        geocoder
          .geocode({
            address: `${rua_cliente || ''} ${numero_cliente || ''} ${
              bairro_cliente || ''
            } ${cidade_cliente || ''} ${uf_cliente || ''}`
          })
          .then((result) => {
            const [location] = result.results;

            const dadosTaxa = estabelecimentoEntregaAreaCliente(areasEntrega, {
              bairro_cliente,
              cidade_cliente,
              localizacao_cliente: location.geometry.location,
              uf_cliente
            });

            definirEMostrarDadosEntrega(dadosTaxa);
          })
          .catch((err) => {
            console.log(err);
            enderecoDispatcher(clearEndereco());
            message.error(
              'Erro ao tentar localizar o endereço. Tente novamente selecionando o endereço novamente.'
            );
          });
      } else {
        const dadosTaxa = taxasentrega.find(
          ({ bairro, cidade, uf }) =>
            unaccentCaseInsensitiveEqual(bairro, bairro_cliente) &&
            unaccentCaseInsensitiveEqual(cidade, cidade_cliente) &&
            unaccentCaseInsensitiveEqual(uf, get_sigla_uf(uf_cliente))
        );

        definirEMostrarDadosEntrega(dadosTaxa);
      }
    }
  }, [endereco, estabelecimento, window.google?.maps]);

  return (
    <>
      <DeliveryHeader pedidosPendentes={pedidosPendentes} />
      {pedidosPendentes.length > 0 && (
        <NotificacaoPagamentoPendente pedidosPendentes={pedidosPendentes} />
      )}
      <Layout className="layout">
        <Content
          style={{
            padding: '0 30px',
            background: '#fefefe',
            minHeight: '800px'
          }}
        >
          {children}
          {width < 768 && (
            <div
              style={{
                overflow: 'auto',
                height: 64,
                width: '100%',
                background: 'transparent'
              }}
            />
          )}
        </Content>
      </Layout>

      {width < 768 ? (
        <DeliveryFooter pedidosPendentes={pedidosPendentes} />
      ) : (
        ''
      )}

      <LgpdBanner />
    </>
  );
};
export default DeliveryLayout;
